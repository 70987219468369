<template>
  <my-echarts :option="option" :style="{ width: props.width, height: props.height }"></my-echarts>
</template>

<script setup>
import { ref, watch } from "vue";
const props = defineProps({
  dataSource: {
    type: Array,
    default: () => [],
  },
  legendData: {
    type: String,
    default: '',
  },
  leftTitle: {
    type: String,
    default: "",
  },
  width: {
    type: String,
    default: "448px",
  },
  height: {
    type: String,
    default: "230px",
  },
});
let opt = { index: 0 };
const option = ref({});
const initData = () => {
  option.value = {
    dataset: {
      source: props.dataSource,
    },
    tooltip: {
      show: false,
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    //图表位置
    grid: {
      top: 30,
      left: 0,
      bottom: 0,
      right: 0,
      // bottom: "12%",
      containLabel: true,
    },
    xAxis: {
      show: false,
      type: "value",
    },
    yAxis: [
      {
        type: "category",
        inverse: true, //升序
        splitLine: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: {
          inside: true,
          // interval: 0, //横轴信息全部显
          splitNumber: 50,
          verticalAlign: "bottom",
          fontSize: 14,
          letterSpacing: 0.35,
          align: "left",
          padding: [0, 0, 12, -5],
          formatter: function (value, index) {
            if (opt.index === 0 && index < 3) {
              return ( "{idx" + index + "|" + "TOP" + (1 + index) + "} {title|" + (value.split('@')[0]) + "}");
            } else {
              return ( "{idx|" + "TOP" + (1 + index + opt.index) + "} {title|" + (value.split('@')[0]) + "}");
            }
          },
          rich: {
            idx0: { color: "#FCD41A", fontStyle: "italic", fontFamily: 'YouSheBiaoTiHei' },
            idx1: { color: "#fff", fontStyle: "italic", fontFamily: 'YouSheBiaoTiHei' },
            idx2: { color: "#FC9F1A", fontStyle: "italic", fontFamily: 'YouSheBiaoTiHei' },
            idx: { color: "#40B5FF", fontStyle: "italic", fontFamily: 'YouSheBiaoTiHei' },
            title: {
              fontSize: 14,
              color: 'rgba(246,249,254,.8)',
              fontStyle: "italic",
              padding: [0, 0, 0, 12],
            },
          },
        },
        // offset: 10,//key和图间距
      },
      {
        type: "category",
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: {
          show: true,
          inside: true,
          color: "#fff",
          fontSize: "18",
          letterSpacing: 0.45,
          fontStyle: 'italic',
          verticalAlign: "bottom",
          fontFamily: 'YouSheBiaoTiHei',
          padding: [0, 0, 10, -5],
        },
        splitArea: { show: false },
        splitLine: { show: false },
        inverse: "true", // 排序
        data: props.dataSource.map(item => item.count), // 数据
      },
    ],
    series: [
      {
        realtimeSort: true, //柱状图自动排序，排序自动让Y轴名字跟着数据动
        name: "数量",
        type: "bar",
        barWidth: 8,
        barGap: '20%',
        smooth: true,
        valueAnimation: true,
        // 背景
        showBackground: true, 
        backgroundStyle: {
          color: "rgba(0,0,0,.2)",
          barBorderRadius: 2,
        },
        itemStyle: {
          emphasis: {
            barBorderRadius: 2,
          },
          //颜色样式部分
          normal: {
            barBorderRadius: 2,
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 1,
              y2: 0,
              colorStops: [
                {
                  offset: 0,
                  color: "#40B5FF",
                },
                {
                  offset: 1,
                  color: "#2270FF",
                },
              ],
            },
          },
        },
      },
    ],
    //动画部分
    animationDuration: 0,
    animationDurationUpdate: 1000,
    animationEasing: "linear",
    animationEasingUpdate: "linear",
  };
};
watch( () => props.dataSource, () => 
  {
    initData();
  },
  { 
    deep: true,
    immediate: true,
  }
);
</script>