<template>
  <my-echarts :option="option" :style="{ width: props.width, height: props.height }"></my-echarts>
</template>

<script setup>
import { ref, watch, defineExpose } from "vue";
const props = defineProps({
  dataSource: {
    type: Array,
    default: () => [],
  },
  legendData: {
    type: String,
    default: '',
  },
  leftTitle: {
    type: String,
    default: "",
  },
  width: {
    type: String,
    default: "448px",
  },
  height: {
    type: String,
    default: "230px",
  },
  showNumber: {
    type: Boolean,
    default: false
  } 
});
const option = ref({});
const initData = () => {
  option.value = {
    dataset: {
      source: props.dataSource,
    },
    grid: {
      bottom: "12%",
      left: "12%"
    },
    title: {
      show: false,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // 坐标轴指示器，坐标轴触发有效
        type: "line", // 默认为直线，可选为：'line' | 'shadow'
      },
    },
    legend: {
      show: false,
      data: [props.legendData],
      right: "3%",
      top: "10%",
      itemWidth: 11,
      itemHeight: 11,
      textStyle: {
        color: "#ffffff",
        fontSize: 13,
      },
    },
    toolbox: {
      show: false,
    },
    xAxis: {
      type: "category",
      axisLine: {
        lineStyle: {
          // 横坐标线
          color: "rgba(255, 129, 109, 0.1)",
          width: 1,
        },
      },
      axisLabel: {
        textStyle: {
          // 横坐标字体
          color: "rgba(246, 249, 254, 0.8)",
          fontSize: 12,
          fontStyle: "italic",
        },
      },
      boundaryGap: false
    },
    yAxis: {
      name: props.leftTitle,
      nameTextStyle: {
        color: "#f6f9fe",
        fontSize: 12,
        fontStyle: "italic",
      },
      type: "value",
      scale: true,
      axisLine: {
        show: false,
      },
      splitNumber: 3,
      axisTick: {
        show: false,
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "rgba(119, 180, 255, .1)",
          width: 1,
          type: "dashed",
        },
      },
      axisLabel: {
        // 纵坐标数值
        fontSize: 14,
        // margin: 12,
        fontStyle: "italic",
        color: "#f6f9fe",
      },
      min: 0,
      boundaryGap: [0.2, 0.2],
    },
    series: [
      {
        smooth: true,
        name: props.legendData || '',
        type: "line",
        symbol: props.showNumber ? 'emptyCircle' : "none",
        label: {
          show: props.showNumber,
          color: '#fff'
        },
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              // 渐变颜色
              {
                offset: 0,
                color: "#3DB0FF",
              },
              {
                offset: 1,
                color: "#217AFE",
              },
            ],
            global: false,
          },
        },
        yAxisIndex: 0,
      },
    ],
  };
};
watch(() => props.dataSource, () => 
  { 
    initData()
  },
  { immediate: true, deep: true }
);
defineExpose({
  option,
});
</script>

<style scoped lang="less">
</style>