<template>
    <my-echarts :option="option" :style="{ width: props.width, height: props.height }"></my-echarts>
</template>
<script setup>
import * as echarts from 'echarts';
import "echarts-gl";
import { getPie3D } from "./pie3D";
import { onMounted, ref, watch, defineExpose } from 'vue';
const props = defineProps({
    dataSource: {
        type: Array,
        default: () => [],
    },
    width: {
        type: String,
        default: "448px",
    },
    height: {
        type: String,
        default: "200px",
    },
    id: {
        type: String,
        default: "main"
    },
  
});
const echartRef=ref();
// const initEcharts = () => {
//     var myChart = echarts.init(document.getElementById(props.id));
//     // 绘制图表
//     initData();
//     myChart.setOption(option.value);
// }
let color = ["#2AB1FF", "#13CDE1", "#2C63FF", "#FFC45C", "#3BFFB5"];
const optionsData = ref([]);
const option = ref({});
const initData = () => {
    if(!props.dataSource || !props.dataSource.length)return
    let list=[];
    props.dataSource.forEach((item, index) => {
        list.push({
            selected: true,
            itemStyle: {
                color: color[index],
            },
            name:item.title,
            value:item.rate || item.count,
        })
    });
    optionsData.value=list;
    const series = getPie3D(optionsData.value, 0.5, 1); // 可做为调整内环大小 0为实心圆饼图，大于0 小于1 为圆环
    series.push({
        name: "pie2d",
        type: "pie",
        label: {
            show: false
        },
        labelLine: {
        },
        minAngle: 10, //最小角度
        startAngle: 250, //起始角度，支持范围[0, 360]。
        clockwise: false, //饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
        radius: ["36%", "90%"],
        center: ["60%", "60%"],
        data: [...optionsData.value, { value: 0, itemStyle: { color: 'transparent' } }],
        itemStyle: {
            opacity: 0,
        },
    });
    // 准备待返回的配置项，把准备好的 legendData、series 传入。
    option.value = {
        legend: {
            show: true,
            tooltip: {
                show: true,
            },
            orient: "vertical",
            data: props.dataSource.map(item=>item.title),
            selectedMode: false,
            top: "13%",
            itemWidth: 8,
            itemHeight: 14,
            itemGap: 20,
            itemRadius: 20,
            left: "10%",
            textStyle: {
                color: "#F6F9FE",
                fontSize: 14,
                rich: {
                    title: {
                        padding: [28, 0, 8, 0],
                        color: 'rgba(246, 249, 254, .8)',
                        fontStyle: 'italic',
                    },
                    count: {
                        fontSize: 20,
                        color: '#f6f9fe',
                        fontStyle: 'italic',
                        fontFamily: 'YouSheBiaoTiHei'
                    }
                },
            },
            formatter: function (name,v) {
                let res = optionsData.value.filter((v) => v.name === name);
                res = res[0] || {};
                const p = res.value;
                return "{title|" + name + "}" + '\n' + "{count|" + p + "%}";
            },
        },
        animation: true,
        tooltip: {
            show: false,
        },
        labelLine: {
            show: false,
        },
        label: {
            show: false,
        },
        xAxis3D: {
            min: -1,
            max: 1,
        },
        yAxis3D: {
            min: -1,
            max: 1,
        },
        zAxis3D: {
            min: -1,
            max: 1,
        },
        grid3D: {
            show: false,
            boxHeight: 20,
            left: "50%",
            top: 0,
            width: "50%",
            viewControl: {
                distance: 240,
                alpha: 25,
                beta: 80,
                autoRotate: false, // 自动旋转
                rotateSensitivity: 0,
                zoomSensitivity: 0,
                panSensitivity: 0,
            },
        },
        series: series,
    };
}
watch(() => props.dataSource, () => {
    initData()
},
    { immediate: true, deep: true }
);
// onMounted(() => {
//     initEcharts()
// })
defineExpose({
  option,
});
</script>
<style lang="less" scoped></style>