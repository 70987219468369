import ScaleBox from '@/components/ScaleBox/index.vue'
import MyModal from './my-modal'
import CountScroll from '@/components/countScroll/index.vue'
import MyEcharts from '@/components/my-echarts/index.vue'
import BarEcharts from '@/echarts/barEcharts/index.vue'
import LineEcharts from '@/echarts/lineEcharts/index.vue'
import LineBarEcharts from '@/echarts/lineBarEcharts/index.vue'
import RankingEcharts from '@/echarts/rankingEcharts/index.vue'
import ScrollList from '@/components/scrollList/index.vue'
import DoubleLineEcharts from '@/echarts/doubleLineEcharts/index.vue'
import BubbleEcharts from '@/echarts/bubbleEcharts/index.vue'
import PictogramEcharts from '@/echarts/pictogramEcharts/index.vue'
import BarRankingEcharts from '@/echarts/barRankingEcharts/index.vue'
import PieEcharts from '@/echarts/pieEcharts/index.vue'
import TwoDPieEcharts from '@/echarts/TwoDPieEcharts/index.vue'
import BarStackEcharts from '@/echarts/barStackEcharts/index.vue'

const MyComponen = {
  install: function (Vue) {
    Vue.component('ScaleBox', ScaleBox);
    Vue.component('MyModal', MyModal);
    Vue.component('CountScroll', CountScroll);
    Vue.component('MyEcharts', MyEcharts);
    Vue.component('BarEcharts', BarEcharts);
    Vue.component('LineEcharts', LineEcharts);
    Vue.component('LineBarEcharts', LineBarEcharts);
    Vue.component('RankingEcharts', RankingEcharts);
    Vue.component('ScrollList', ScrollList);
    Vue.component('DoubleLineEcharts', DoubleLineEcharts);
    Vue.component('BubbleEcharts', BubbleEcharts);
    Vue.component('PictogramEcharts', PictogramEcharts);
    Vue.component('BarRankingEcharts', BarRankingEcharts);
    Vue.component('PieEcharts', PieEcharts);
    Vue.component('TwoDPieEcharts', TwoDPieEcharts);
    Vue.component('BarStackEcharts', BarStackEcharts);
  }
}

export default MyComponen