import request from '@/utils/request'
import qs from 'qs'
import { getParameterMap } from "@/utils";

export function getVerificationCode(data) {
  return request({
    url: '/code',
    method: 'get',
    responseType: 'arraybuffer',
    params: data,
  })
}

export function login({ username, password, code, randomStr }) {
  return request({
    url: '/auth/oauth/token',
    headers: {
      isToken: false,
      'TENANT-ID': '1',
      'Authorization': 'Basic cGlnOnBpZw=='
    },
    method: 'post',
    params: { randomStr, code, grant_type: 'password' },
    data: qs.stringify({ username, password })
  })
}

export function getMenu() {
  return request({
    url: '/admin/menu/forj-data-menu',
    method: 'get',
    params: { systemType: getParameterMap()?.get('type') }
  })
}

export function getCheck(data) {
  return request({
    url: '/success.json',
    method: 'post',
    data: data
  })
}