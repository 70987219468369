import { login, getMenu } from '@/api/login'
import { setToken, setUserInfo } from '@/utils'
import router from '@/router'

const user = {
  state: {
    token: "",
    name: "管理员",
    welcome: "",
    avatar: "",
    roles: [],
    info: {},
    router: [],
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_INFO: (state, info) => {
      state.info = info;
    },
    SET_ROUTER: (state, router) => {
      state.router = router;
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then((response) => {
          const token = response.access_token;
          setToken(token);
          commit("SET_TOKEN", token);
          commit("SET_INFO", response);
          setUserInfo(response);
          resolve();
        }).catch((error) => {
          reject(error); 
        });
      });
    },

    // 获取菜单
    GetMenu({ commit }) {
      return new Promise((resolve, reject) => {
        getMenu({}).then((response) => {
          let menu = response.data.map(({ id, path, name, children, meta }) => ({
            id,
            component: path == 'noPath' ? '' : path,
            level: 1,
            route: path == 'noPath' ? '' : `/${path}`,
            name: path.split('/').pop(),
            title: name,
            meta,
            children: children.map(({ id, path, name, meta }) => ({
              id,
              component: path,
              level: 2,
              route: `/${path}`,
              name: path.split('/').pop(),
              title: name,
              meta,
            }))
          }))
          commit("SET_ROUTER", menu);
          localStorage.setItem('router', JSON.stringify(menu));
          resolve();
        }).catch((error) => {
          commit("SET_TOKEN", "");
          setToken("");
          reject(error);
        });
      });
    },

    // 登出
    Logout({ commit }) {
      commit("SET_TOKEN", "");
      commit("SET_ROUTER", []);
      localStorage.setItem('router', '[]');
      setToken("");
      setTimeout(() => {
        router.replace('loginPage');
        window.location.reload();
      }, 300);
    },
  },
};

export default user
