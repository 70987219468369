<template>
  <count-to
    :startVal="startVal"
    :endVal="props.count * 1"
    :duration="3000"
    :separator="props.separator"
    :decimals="
      String(props.count).indexOf('.') > -1
        ? String(props.count).split('.')[1].length
        : null
    "
  >
  </count-to>
</template>

<script setup>
import CountTo from '@/components/countTo/vue-countTo.vue';
import { ref, watch } from 'vue';
const startVal = ref(0);
const props = defineProps({
  count: {
    type: [Number, String],
    default: 0
  },
  separator: {
    type: String,
    default: ','
  }
})
watch(() => props.count, (newValue, oldValue) => {
  startVal.value = oldValue * 1;
})
</script>