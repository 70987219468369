<template>
  <my-echarts :option="option" :style="{ width: props.width, height: props.height }"></my-echarts>
</template>

<script setup>
import { ref, watch, defineExpose } from "vue";
const props = defineProps({
  dataSource: {
    type: Array,
    default: () => [],
  },
  legendData: {
    type: String,
    default: '',
  },
  leftTitle: {
    type: String,
    default: "",
  },
  width: {
    type: String,
    default: "448px",
  },
  height: {
    type: String,
    default: "210px",
  },
});
const option = ref({});
const initData = () => {
  option.value = {
    tooltip: {
    trigger: "axis",
    axisPointer: {
      type: 'shadow'
    },
  },
  dataset: {
    source: props.dataSource
  },
  grid: { // 控制位置
    left: "12%",
    top: "5%",
    bottom: "12%",
    right: "8%",
  },
  xAxis: {
    type: 'category',
    axisLine: {
      lineStyle: { // 横坐标线
        color: "rgba(255, 129, 109, 0.1)",
        width: 1, //这里是为了突出显示加上的
      },
    },
    axisLabel: {
      textStyle: { // 横坐标字体颜色
        color: "rgba(246, 249, 254, 0.8)",
        fontSize: 12,
        fontStyle: 'italic'
      },
    },
  },
  yAxis: [
    {
      splitNumber: 3, // 纵坐标间隔
      axisLabel: {
        formatter: '{value}',
        textStyle: {
          color: "rgba(246, 249, 254, .8)",
          fontStyle: 'italic'
        },
      },
      splitLine: { // 纵坐标间隔线
        show: true,
        lineStyle: {
          color: "rgba(119, 180, 255, .1)",
          width: 1,
          type: "dashed",
        },
      },
    },
  ],
  series: [
    {
      name: props.legendData,
      type: "pictorialBar",
      barCategoryGap: "0%",
      symbol: "path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z",
      itemStyle: {
        normal: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#40FFCB", //  0%  处的颜色
              },
              {
                offset: 1,
                color: "#2271FF", //  100%  处的颜色
              },
            ],
          },
        },
      },
      z: 10,
    },
  ],
  };
};
watch(() => props.dataSource, () => 
  { 
    initData()
  },
  { immediate: true, deep: true }
);
defineExpose({
  option,
});
</script>

<style scoped lang="less">
</style>