<template>
  <my-echarts :option="option" :style="{ width: props.width, height: props.height }" ref="barRankRef"></my-echarts>
</template>

<script setup>
import { ref, watch, defineExpose, onBeforeUnmount } from "vue";
const props = defineProps({
  dataSource: {
    type: Array,
    default: () => [],
  },
  legendData: {
    type: String,
    default: '',
  },
  leftTitle: {
    type: String,
    default: "",
  },
  width: {
    type: String,
    default: "448px",
  },
  height: {
    type: String,
    default: "230px",
  },
});

const option = ref({});
const initData = () => {
  option.value = {
    dataset: {
      source: props.dataSource,
    },
    tooltip: {
      show: false,
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    //图表位置
    grid: {
      top: 30,
      bottom: 0,
      left: 0,
      right: 0,
      // containLabel: true, // grid 区域是否包含坐标轴的刻度标签, 常用于防止标签溢出的场景
    },
    xAxis: {
      show: false,
      type: "value",
    },
    yAxis: [
      {
        type: "category",
        inverse: true, //升序
        splitLine: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: {
          inside: true,
          verticalAlign: "bottom",
          fontSize: 14,
          letterSpacing: 0.35,
          align: "left",
          padding: [0, 0, 10, -6],
          color: 'rgba(246,249,254,.8)',
          fontStyle: 'italic'
        },
      },
      {
        type: "category",
        inverse: true,
        axisTick: 'none',
        axisLine: 'none',
        show: true,
        axisLabel: {
          inside: true,
          verticalAlign: "bottom",
          color: "#fff",
          fontSize: "18",
          letterSpacing: 0.45,
          fontStyle: 'italic',
          fontFamily: 'YouSheBiaoTiHei',
          padding: [0, 0, 6, -5],
          formatter: function (value) {
            return value;
          },
        },
        splitArea: { show: false },
        splitLine: { show: false },
        data: props.dataSource.map(item => item.count), // 数据
      },
    ],
    series: [
      {
        name: "",
        type: "bar",
        barWidth: 8,
        smooth: true,
        showBackground: true, 
        backgroundStyle: {
          color: "rgba(0,0,0,.2)",
          barBorderRadius: 2,
        },
        itemStyle: {
          emphasis: {
            barBorderRadius: 2,
          },
          //颜色样式部分
          normal: {
            barBorderRadius: 2,
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 1,
              y2: 0,
              colorStops: [
                {
                  offset: 0,
                  color: "#40B5FF",
                },
                {
                  offset: 1,
                  color: "#2270FF",
                },
              ],
            },
          },
        },
    }],
   dataZoom: [
    {
      yAxisIndex: [0, 1], // 这里是从X轴的0刻度开始
      show: false, // 是否显示滑动条，不影响使用
      type: "slider", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
      startValue: 0, // 从头开始。
      endValue: 4 // 展示到第几个。
    }
   ]
  };
};

const timer = ref(null);
timer.value = setInterval(() => {
  if (option.value.dataZoom[0].endValue >= props.dataSource.length - 1) {
    option.value.dataZoom[0].endValue = 4
    option.value.dataZoom[0].startValue = 0
  } else {
    option.value.dataZoom[0].endValue = option.value.dataZoom[0].endValue + 1
    option.value.dataZoom[0].startValue = option.value.dataZoom[0].startValue + 1
  }
}, 2000);

onBeforeUnmount(() => {
  if(timer.value) {
    clearInterval(timer.value)
  }
})

watch(() => props.dataSource, () => 
  { 
    initData()
  },
  { immediate: true, deep: true }
);
defineExpose({
  option,
});
</script>

<style scoped lang="less">
</style>