<template>
  <my-echarts :option="option" :style="{ width: props.width, height: props.height }"></my-echarts>
</template>

<script setup>
import { ref, watch, defineExpose } from "vue";
const props = defineProps({
  dataSource: {
    type: Array,
    default: () => [],
  },
  width: {
    type: String,
    default: "448px",
  },
  height: {
    type: String,
    default: "230px",
  },
  unit:{
    type: String,
    default: "",
  },
  type:{
    type: String,
    default: "",
  },
});
let color = ['#55B7FA', '#3043FF', '#FFA63C', '#3081FF', '#FFE25A', '#3CFFEA'];
const option = ref({});
const optionsData = ref([]);
const initData = () => {
  let list=[];
    props.dataSource.forEach((item, index) => {
        list.push({
            name:item.title,
            value:item.count,
        })
    });
    if(props.dataSource.length == 0){
      list.push({ value: 0, itemStyle: { color: 'transparent' }})
    }
    optionsData.value=list;
  option.value = {
    // dataset: {
    //   source: props.dataSource,
    // },
    color: color,
    tooltip: {
      trigger: 'item',
      show:false
    },
    legend: {
      orient: 'vertical',
      x: '7%',
      y: 'center',
      itemWidth: 8,
      itemHeight: 14,
      itemGap: 20,
      itemRadius: 20,
      align: 'left',
      textStyle: {
        color: '#F6F9FE',
        fontSize: 14,
        padding: [0, 0, 0, 10],
        rich: {
          title: {
            padding: [28, 0, 8, 0],
            color: 'rgba(246, 249, 254, .8)',
            fontStyle: 'italic',
          },
          count: {
            fontSize: 20,
            color: '#f6f9fe',
            fontStyle: 'italic',
            fontFamily: 'YouSheBiaoTiHei'
          }
        },
      },
      formatter(name) {
        let res = props.dataSource.filter((v) => v.title === name);
        res = res[0] || {};
        const p = res.count;
        return "{title|" + name + "}" + '\n' + "{count|" + p + (res.unit || props.unit || '') + "}";
      },
    },
    series: [
      {
        type: 'pie',
        minAngle: 10,
        radius: ['65%', '80%'],
        center: ['75%', '50%'],
        data: [...optionsData.value,],
        labelLine: {
          normal: {
            show: false,
          },
        },
        label: {
          normal: {
            show: false,
          },
        },
      },
    ],
  };
  if(props.type=='finance'){
    option.value.legend.y="39%";
    option.value.series[0].radius=['50%', '60%'];
  }
};
watch(() => props.dataSource, () => {
  initData()
},
  { immediate: true, deep: true }
);
defineExpose({
  option,
});
</script>

<style scoped lang="less"></style>