import { createRouter, createWebHistory } from "vue-router";
import { getToken, setToken } from "@/utils";
import store from '@/store';
import { message } from 'ant-design-vue';

const router = createRouter({
  history: createWebHistory('/'),
  routes: [
    {
      path: "/login",
      name: "loginPage",
      meta: { title: "loginPage" },
      component: () => import("@/views/login/index.vue"),
    },
    {
      path: "/",
      name: "home",
      meta: { title: "home" },
      component: () => import("@/views/home/index.vue"),
    },
    {
      path: "/energy",
      name: "energy",
      meta: { title: "energy" },
      component: () => import("@/views/energy/index.vue"),
    },
    {
      path: "/finance",
      name: "finance",
      meta: { title: "finance" },
      component: () => import("@/views/finance/index.vue"),
    },
    {
      path: "/vehicle",
      name: "vehicle",
      meta: { title: "vehicle" },
      component: () => import("@/views/vehicle/index.vue"),
    },
    {
      path: "/transit",
      name: "transit",
      meta: { title: "transit" },
      component: () => import("@/views/transit/index.vue"),
    },
    {
      path: "/test",
      name: "test",
      meta: { title: "test" },
      component: () => import("@/views/test/test.vue"),
    },
    // 修改密码
    {
      path: "/changePW",
      name: "changePW",
      meta: { title: "changePW" },
      component: () => import("@/views/changePW/index.vue"),
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'errorPage',
      meta: { hideInMenu: true },
      component: () => import('@/views/error/404.vue')
    }
  ],
});

//处理路由数据
const getRouter = () => {
  const routers = JSON.parse(localStorage.getItem('router')) || [];
  const newRouter = [];
  routers.map((v) => {
    if (v.route) {
      newRouter.push({
        path: v.route,
        name: v.name || v.route.slice(1),
        component: () => import(`@/${v.component}`),
        meta: { ...(v.meta || {}), title: v.title }
      });
    } else if (v.children.length) {
      v.children.map(menu => {
        newRouter.push({
          path: menu.route,
          name: menu.name || menu.route.slice(1),
          component: () => import(`@/${menu.component}`),
          meta: { ...(menu.meta || {}), title: menu.title }
        });
      })
    }
  });
  return newRouter;
};

let doAddRoute = false;
router.beforeEach((to, from, next) => {
  if (to.query.token) {
    setToken(to.query.token);
    store.dispatch('GetMenu').then(() => {
      next(to.path);
    }).catch(err => {
      message.error('获取菜单失败' + (err?.msg || ''));
    });
    return;
  }
  const token = getToken();
  if (!token && to.path !== "/login" && to.path !== "/changePW") {
    next("/login");
  } else if (token && to.path == '/login') {
    next('/');
  } else if (token && !doAddRoute) {
    store.dispatch('GetMenu').then(() => {
      next(to.path);
    }).catch(err => {
      message.error('获取菜单失败' + (err?.msg || ''));
    });
    console.log('组装路由');
    doAddRoute = true;
    const addRouter = getRouter();
    addRouter.length && addRouter.map((v) => router.addRoute('homePage', v));
    next(to.fullPath);
  } else {
    next();
  }
});

router.afterEach(() => {
  console.log("routerAfterEach");
  // 大屏全屏展示兼容
  setTimeout(() => {
    // 创建一个resize事件
    let resizeEvent = new Event('resize');
    // 触发window对象上的resize事件
    window.dispatchEvent(resizeEvent);
  }, 300)
});

export default router;
