<template>
  <div class="ScaleBox"
       ref="ScaleBox"
       :style="{ width: width + 'px', height: height + 'px' }">
    <slot></slot>
  </div>
</template>
 
<script setup>
  import { reactive, ref, onMounted, onUnmounted } from 'vue';
  const scale = ref(0);
  const width = ref(1920);
  const height = ref(1080);
  const ScaleBox = ref(null);

  const getScale = () => {
    // 固定好16：9的宽高比，计算出最合适的缩放比
    // const { width, height } = this;
    const wh = document.documentElement.clientHeight / height.value;
    const ww = document.documentElement.clientWidth / width.value;
    return ww < wh ? ww : wh;
  }

  const setScale = () => {
    // 获取到缩放比例，设置它
    scale.value = getScale();
    if (ScaleBox.value) {
      ScaleBox.value.style.setProperty("--scale", scale.value);
    }
  }

  onMounted(() => {
    setScale();
    window.addEventListener("resize", setScale);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', setScale);
  });

</script>
 
<style lang="less">
#ScaleBox {
  --scale: 1;
}
.ScaleBox {
  position: absolute;
  transform: scale(var(--scale)) translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  transform-origin: 0 0;
  left: 50%;
  top: 50%;
  transition: 0.3s;
  z-index: 999;
  // background: rgba(255, 0, 0, 0.3);
}
</style>
