import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import Ant from 'ant-design-vue';
import MyComponen from '@/components';
import '@/style/global.less';
import 'ant-design-vue/dist/antd.css'; // or 'ant-design-vue/dist/antd.less'
import '@/assets/fonts/fonts.css'

const app = createApp(App);

app.config.globalProperties.log = msg => console.log(msg);
app.config.globalProperties.alert = msg => alert(msg);
app.config.globalProperties.window = window;
app.config.globalProperties.imgBase = '';

app.use(router);
app.use(store);
app.use(Ant);
app.use(MyComponen);
app.mount('#app');