<template>
  <a-config-provider :locale="locale">
    <router-view v-if="['loginPage'].includes($route.name)" />
    <ScaleBox v-else>
      <div class="screen-container">
        <div class="back-area" @click="$router.replace('/');">
          <img v-if="$route.name != 'home'" class="back-image" src="@/assets/svg/back.svg" alt="">
        </div>
        <router-view />
      </div>
    </ScaleBox>
  </a-config-provider>
</template>

<script>
  import zhCN from 'ant-design-vue/es/locale/zh_CN';
  import dayjs from 'dayjs';
  import 'dayjs/locale/zh-cn';
  dayjs.locale('zh-cn');
  export default {
    name: 'App',
    data() {
      return {
        locale: zhCN,
      };
    },
  }
</script>

<style>
.back-area {
  width: 240px;
  height: 80px;
  position: absolute;
  cursor: pointer;
  z-index: 9999;
}
.back-image {
  position: relative;
  width: 24px; 
  height: 24px;
  left: 180px;
  top: 34px;
}
</style>
